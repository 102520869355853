import React from "react";
import Heading from "../../components/Heading";
import { MdMusicNote } from "../../components/Icons";
import { graphql, useStaticQuery } from "gatsby";

const Music = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { id: { eq: "music" } }) {
        html
      }
    }
  `);

  return (
    <section id='music'>
      <Heading icon={MdMusicNote} title='Music' />

      <div
        className='text-justify lg:col-span-4 wow fadeIn'
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />

      <iframe
        title='spotify'
        src='https://open.spotify.com/embed/playlist/0BqwAG4hG3Xa3b0hC2M4Qz?utm_source=generator'
        width='100%'
        height='500'
        frameBorder='0'
        className='mt-5'
        allowtransparency='true'
        allow='encrypted-media'
      ></iframe>
    </section>
  );
};

export default Music;
