import Fade from "@material-ui/core/Fade";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React, { useContext, useEffect, useState } from "react";
import { animateScroll as scroll, scroller } from "react-scroll";
import ThemeContext from "../context/ThemeContext";
import { IoIosMoon, IoIosSunny, MdMenu } from "../components/Icons";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "./more.module.css";
import { Link } from "gatsby";
import Music from "../sections/Music";
import me from "../images/insta.jpg";
import Heading from "../components/Heading";
import { FaEye, FaInstagram } from "react-icons/fa";

export default function MorePage() {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { dark, toggleDark } = useContext(ThemeContext);
  const highlight =
    "bg-primary-100 text-primary-600 px-1 rounded py-1 whitespace-nowrap";
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { isMobile } = require("../utils");
      setIsMobile(isMobile);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const WOW = require("wowjs");
      new WOW.WOW({ live: true, mobile: true }).init();
    }
  }, []);

  const scrollToTop = () =>
    scroll.scrollToTop({
      delay: 50,
      duration: 600,
      smooth: "easeInOutCubic",
    });

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      delay: 50,
      offset: -50,
      duration: 600,
      smooth: "easeInOutCubic",
    });
  };

  const SectionLink = (x) => {
    const Icon = x.icon;

    return (
      <Tooltip title={x.title} placement='right' arrow>
        <div key={x.id} onClick={() => scrollTo(x.id)}>
          <Icon />
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      className={
        (dark ? styles.dark : styles.light) + ` grid grid-cols-1 row-gap-1 px-2`
      }
    >
      {/* modified header */}
      <nav className='container flex items-center justify-between mx-auto pt-8 px-4 pb-8'>
        <Link to='/'>
          <div className='cursor-pointer transform transition ease-in-out delay-150 hover:translate-y-1 hover:scale-110 duration-300'>
            <GatsbyImage className='' {...data.icon.childImageSharp} />
          </div>
        </Link>

        <ul className='md:flex items-center uppercase font-bold tracking-wide'>
          <li>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={dark ? "Turn on the lights" : "Turn off the lights"}
            >
              <div
                className='cursor-pointer hover:text-primary-500'
                onClick={toggleDark}
              >
                {dark ? <IoIosSunny /> : <IoIosMoon />}
              </div>
            </Tooltip>
          </li>
        </ul>
      </nav>
      <div className='container'>
        {/* content */}
        <div id='alert' className='text-center py-4 px-4'>
          <div
            className='bg-teal-600 text-teal-100 px-2 py-1 items-center leading-none rounded-full inline-flex'
            role='alert'
          >
            <span className='bg-teal-500 flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3'>
              New
            </span>
            <span className='font-thin mr-2 text-left flex-auto'>
              Blog section coming up!
            </span>
          </div>
        </div>
        <section id='more' className='py-10 my-4'>
          <Heading icon={FaEye} title='More about me' />
          <figure className='md:flex bg-slate-200  rounded-full p-8 md:p-0 dark:bg-slate-800'>
            <img
              className='w-24 h-24 md:w-64 md:h-auto  md:rounded-md rounded-full mx-auto wow fadeInLeft'
              src={me}
              alt=''
              width='384'
              height='512'
            />

            <div className='pt-6 md:p-8 text-center md:text-left space-y-4 wow fadeIn'>
              <p className='leading-9 md:leading-8'>
                Apart from{" "}
                <span className={highlight}>coding and building software</span>,
                I spend my free time{" "}
                <span className={highlight}>listening to music</span> or
                <span className={highlight}>playing an instrument</span>.
              </p>
              <p className='leading-9 md:leading-8'>
                I am also open to{" "}
                <span className={highlight}>teaching piano</span> to those
                interested
              </p>
              <p className='leading-9 md:leading-8'>
                I happen to have an immense curiosity for many things in life,
                such as <span className={highlight}>literature</span>,{" "}
                <span className={highlight}>history</span> and{" "}
                <span className={highlight}>sports</span>.
              </p>

              <figcaption className='font-medium'>
                <div className='text-sky-500 dark:text-sky-400'>
                  Check out my instagram where I post some{" "}
                  <span className={highlight}>music covers</span> from time to
                  time.
                </div>
                <a
                  href='https://www.instagram.com/sanfernoronha'
                  target='_blank'
                  className='hover:no-underline'
                >
                  <div className='text-slate-700 dark:text-slate-500 flex items-center justify-center md:justify-start space-x-2  my-2'>
                    <FaInstagram className='text-lg' />
                    <div>- sanfernoronha</div>
                  </div>
                </a>
              </figcaption>
            </div>
          </figure>
        </section>
        <Music />
      </div>
      <footer>
        <div className='container mx-auto flex flex-col items-center justify-between py-8'>
          <div className='text-xs sm:text-base p-8 text-center'>
            Built with{" "}
            {!dark ? (
              <span className='animate-pulse'>🖤</span>
            ) : (
              <span className='animate-pulse'>🤍</span>
            )}
            {" by"}
            <br />
            Sanfer Noronha
          </div>
          <div className='container flex justify-between items-center px-4'>
            <ul className='flex justify-between items-center'>
              <li className='mr-8 hover:text-primary-500'>
                <a
                  href='https://www.linkedin.com/in/sanfernoronha/'
                  target='_blank'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    className='bi bi-linkedin'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
                  </svg>
                </a>
              </li>
              <li className='hover:text-primary-500'>
                <a href='https://github.com/sanfernoronha' target='_blank'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    className='bi bi-github'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z' />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}
